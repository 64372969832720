import { Button, Grid, Link } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cameraIcon from '../../assets/camera-icon.svg';
import '../../styles/components/stepperValidation.scss';
import '../../styles/components/toc.scss';
import Toaster from '../alerts/Toaster';
import Loader from '../loader/Loader';
import { uploadFile, useStyles } from './DocumentUploaderCommon';
import eventTrackingService from '../../services/eventtracking.service';

export const ManualDocumentUploader = (props) => {
    const { title, hintInfo, document, hashKey, onDocumentUploaded, onHandleNext, sampleSrc } = props;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Se produjo un error');
    const [imgSrc, setImgSrc] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(null);
    const [xsValue, setXsValue] = useState(12);
    const [listener, setListener] = useState(null);
    const eventPathTitle = useRef('');

    function useMedia(query) {
        const [matches, setMatches] = useState(window.matchMedia(query).matches);

        useEffect(() => {
            let media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }

            if (media.matches) {
                setXsValue(12);
            } else {
                setXsValue(12);
            }
            setListener(() => setMatches(media.matches));
            media.addListener(listener);
        }, [query]);

        return matches;
    }

    useEffect(() => {
        if (title) {
            if (title.includes('frente')) {
                eventPathTitle.current = 'dni-frente';
            }
            if (title.includes('dorso')) {
                eventPathTitle.current = 'dni-dorso';
            }
        }
    }, [title]);

    useEffect(() => {
        const urlPathEvent = `/prestamos-personales/${eventPathTitle.current}`;
        if (imgSrc) {
            eventTrackingService.trackContentViewEvent(`${urlPathEvent}-confirmacion`);
        } else {
            eventTrackingService.trackContentViewEvent(`${urlPathEvent}-inicio`);
        }
    }, [imgSrc]);

    const matches = useMedia('(orientation: landscape)');

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length > 0) {
                const file = target.files[0];
                const token = { session_Id: 'N/A' };
                const setters = {
                    setLoading,
                    setImgSrc,
                    onDocumentUploaded,
                    setMessage,
                    setOpen,
                };
                uploadFile(t, hashKey, file, URL.createObjectURL(file), 'Manual', token, document, setters);
            }
        }
    };

    const handleRecapture = () => {
        setImgSrc(null);
        onDocumentUploaded(null);
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const hiddenFileInput = React.useRef(null);

    const handleInputFileClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleInputFileChange = (event) => {
        handleCapture(event.target);
    };

    const CaptureDNI = () => {
        return !imgSrc ? (
            <>
                <Grid item xs={12} className="center" style={{ marginBottom: '20px' }}>
                    <Button
                        className="width-100-per"
                        onClick={handleInputFileClick}
                        variant="contained"
                        color="primary"
                        startIcon={<img src={cameraIcon} alt="" />}>
                        <span className="fs-16 fw-400">SACAR FOTO</span>
                    </Button>
                </Grid>
                <div className="center mt-2">
                    <input
                        accept="image/*"
                        ref={hiddenFileInput}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={handleInputFileChange}
                        style={{ display: 'none' }}
                    />
                </div>
            </>
        ) : (
            <Grid item xs={12} lg={4}></Grid>
        );
    };

    return (
        <>
            <Loader loading={loading} />
            {document && (
                <>
                    <Grid item container xs={12} alignItems="center">
                        <p className="fs-20 fw-500 color-primary">{title} </p>
                        <div className="mt-1"></div>
                    </Grid>

                    {!imgSrc ? (
                        <>
                            <div className="center mb-3 mt-4">
                                <img src={sampleSrc} id="oldDni" alt=""></img>
                            </div>
                            <Grid item container xs={xsValue} className="mb-2">
                                {hintInfo}
                            </Grid>
                        </>
                    ) : (
                        <Grid item container xs={xsValue} />
                    )}

                    <Grid item container style={{ zIndex: 100, marginTop: '25px' }} xs={xsValue} justifyContent="center" alignItems="center">
                        {!imgSrc && <CaptureDNI />}

                        {imgSrc && (
                            <div className="">
                                <img className={classes.captureStylePortrait} src={imgSrc} alt={document.name} />
                            </div>
                        )}
                    </Grid>

                    {imgSrc && (
                        <>
                            <Grid item xs={xsValue} style={{ marginTop: '50px', marginBottom: '20px' }}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    style={{ color: '#00A1DA', fontSize: '16px', fontWeight: '600' }}
                                    onClick={handleRecapture}
                                    className="center width-100-per">
                                    Capturar nuevamente
                                </Link>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={xsValue} className="">
                        {imgSrc && (
                            <div className="mt-2 width-100-per mb-7">
                                <Button onClick={onHandleNext} className="width-100-per" variant="contained" color="primary">
                                    <span className="fs-16 fw-400">Siguiente</span>
                                </Button>
                            </div>
                        )}
                    </Grid>

                    {open && (
                        <div className="mt-4">
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};
