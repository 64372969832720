import TagManager from 'react-gtm-module';
import md5 from 'js-md5';
import { GTM_CODE, getEnv } from '../env';

const tagManagerArgs = {
    gtmId: `${getEnv(GTM_CODE)}`,
};

const KEY_DNI_ID = 'dniId';
const KEY_IS_CLIENT = 'isClient';
const KEY_TESTING_GROUP = 'testingGroup';
const KEY_CUSTOMER_SEX = 'customerSex';

const getCommonEventProperties = () => {
    return {
        clientId: localStorage.getItem(KEY_DNI_ID),
        cd9: localStorage.getItem(KEY_IS_CLIENT),
        cd10: localStorage.getItem(KEY_TESTING_GROUP),
        género: localStorage.getItem(KEY_CUSTOMER_SEX),
    };
};

const eventTrackingService = {
    initialize: () => {
        TagManager.initialize(tagManagerArgs);
    },

    setCustomerSex: (customerSex = null) => {
        if (!customerSex) {
            return;
        }
        localStorage.setItem(KEY_CUSTOMER_SEX, customerSex);
        console.debug(`(${KEY_CUSTOMER_SEX},${customerSex}) saved to local storage`);
    },

    setDniId: (dniId) => {
        if (!dniId) {
            return;
        }
        const hashedDniId = md5(dniId).toUpperCase();
        localStorage.setItem(KEY_DNI_ID, hashedDniId);
        console.debug(`(${KEY_DNI_ID},${hashedDniId}) saved to local storage`);
    },

    setIsClient: (isClient) => {
        if (!isClient) {
            return;
        }
        localStorage.setItem(KEY_IS_CLIENT, isClient);
        console.debug(`(${KEY_IS_CLIENT},${isClient}) saved to local storage`);
    },

    setTestingGroup: (testingGroup) => {
        if (!testingGroup) {
            return;
        }
        localStorage.setItem(KEY_TESTING_GROUP, testingGroup);
        console.debug(`(${KEY_TESTING_GROUP},${testingGroup}) saved to local storage`);
    },
    // eventname (mandatory): the key of the event
    // label (optional): tag for the event
    // value (optional): if used, must be numeric
    // step (optional): indicator for funnel step. By default it uses the path from the url
    // method (optional): metodo por el que se accedió al evento
    trackEvent: (eventName, label, value, step, method) => {
        try {
            const eventProperties = {
                event: 'interaction',
                action: eventName,
                label: label,
                value: value,
                step: step || window.location.pathname,
                'onboarding-method': method,
                ...getCommonEventProperties(),
            };
            TagManager.dataLayer({
                ...tagManagerArgs,
                dataLayer: {
                    ...eventProperties,
                },
            });
        } catch (e) {
            // Error sending event tracking event
            console.log('Error sending event', e);
        }
    },

    trackEventWithData: (eventName, data = {}, step, method) => {
        try {
            const eventProperties = {
                event: 'interaction',
                action: eventName,
                step: step || window.location.pathname,
                'onboarding-method': method,
                ...data,
                ...getCommonEventProperties(),
            };
            TagManager.dataLayer({
                ...tagManagerArgs,
                dataLayer: {
                    ...eventProperties,
                },
            });
        } catch (e) {
            // Error sending event tracking event
            console.error('Error sending event', e);
        }
    },
    // url: partial path of the call that failed
    // errorNumber: error code received either from backend or typicals (40X, 50X)
    // errorDescription: error description from browser o backend
    trackError: (url, errorNumber, errorDescription) => {
        try {
            const eventProperties = {
                event: 'error',
                action: 'error-service',
                target: url,
                targetProperties: errorDescription,
                value: errorNumber,
                ...getCommonEventProperties(),
            };
            TagManager.dataLayer({
                ...tagManagerArgs,
                dataLayer: {
                    ...eventProperties,
                },
            });
        } catch (e) {
            // Error sending event tracking event
        }
    },
    // errorNumber: error code received from TOC service
    // errorDescription: error description from browser o backend
    // instance: autocapture/liveness
    trackTocError: (errorNumber, errorDescription, instance) => {
        try {
            const eventProperties = {
                event: 'error',
                action: 'error-service',
                label: `toc_${errorNumber}`,
                target: instance,
                targetProperties: errorDescription,
                value: errorNumber,
                ...getCommonEventProperties(),
            };
            TagManager.dataLayer({
                ...tagManagerArgs,
                dataLayer: {
                    ...eventProperties,
                },
            });
        } catch (e) {
            // Error sending event tracking event
        }
    },

    trackContentViewEvent: (url) => {
        try {
            const eventProperties = {
                event: 'contentView',
                action: 'page-view',
                target: url,
                ...getCommonEventProperties(),
            };
            TagManager.dataLayer({
                ...tagManagerArgs,
                dataLayer: {
                    ...eventProperties,
                },
            });
        } catch (e) {
            // Error sending event tracking event
        }
    },

    getClientId: () => {
        return window.gaGlobal?.vid;
    },
};

export default eventTrackingService;
