import { Box, Button, Grid, Hidden, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import eventTrackingService from '../../services/eventtracking.service';
import loanRequestService from '../../services/loanRequest.service';
import termService from '../../services/term.service';
import workflowService from '../../services/workflow.service';
import '../../styles/components/tabs.scss';
import Toaster from '../alerts/Toaster';
import Loader from '../loader/Loader';
import { calculateAge } from './../../components/utils/dateUtils';
import { ANALYTICS_EVENT, ERROR, LOAN_REQUEST_STATUS, ROUTE } from './../../constants/global';
import AcceptTermsCheckbox from './AcceptTermsCheckbox';
import { CheckCuit } from './checkDni/checkCuit';
import { CheckDni } from './checkDni/checkDni';
import { CheckEmail } from './checkEmail/checkEmail';
import { CheckPhone } from './checkphone/checkPhone';
import { VerifyPin } from './checkphone/checkPin';
import HelperPopup from './helperPopup';
import { ApiContext } from './useLogin';

export const styles = {
    container: (isFullWidth) => ({
        marginTop: isFullWidth ? '20px' : '20px',
        minWidth: isFullWidth ? '90%' : '100%',
        marginLeft: isFullWidth ? '10px' : '0px',
        maxWidth: '100%',
        width: isFullWidth ? '90%' : '100%',
    }),
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div>
            {value === index && (
                <Box p={3}>
                    <span>{children}</span>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: null,
        height: null,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

// Creamos el context
export const TabsContext = createContext();

export default function FullWidthTabs(props) {
    const size = useWindowSize();
    const width = size.width;
    const { request, setRequest, cuits, setCuits, pin, number, setNumber, email, setEmail, loading, setLoading } = useContext(ApiContext);
    const { clientNumber } = number;
    const { clientPin } = pin;
    const { clientEmail } = email;
    const [open, setOpen] = useState(false);
    const [dni, setDni] = useState('');
    const [manualFirstName, setManualFirstName] = useState('');
    const [manualLastName, setManualLastName] = useState('');
    const { t } = useTranslation();
    const analyticsStep = 2;
    const [promoterInfo, setPromoterInfo] = useState(null);
    const [encryptedQueryParams, setEncryptedQueryParams] = useState(null);
    const [customerDataCompleted, setCustomerDataComplete] = useState(false);
    const mediaMatch = window.matchMedia('(min-width: 500px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [marketingData, setMarketingData] = useState({});
    const [optionCuitVisible, setOptionCuitVisible] = useState(false);

    const [checkDniVisible, setCheckDniVisible] = useState(true);
    const [checkEmailVisible, setCheckEmailVisible] = useState(false);
    const [checkPhoneVisible, setCheckPhoneVisible] = useState(false);
    const [checkPinVisible, setCheckPinVisible] = useState(false);

    const [showDniInput, setShowDniInput] = useState(true);
    const [showEmailInput, setShowEmailInput] = useState(true);
    const [showPhoneInput, setShowPhoneInput] = useState(true);

    const [requestButtonVisible, setrequestButtonVisible] = useState(false);
    const [termsId, setTermsId] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const handler = (e) => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    useEffect(() => {
        // reset DNI
        if (showDniInput) {
            setShowEmailInput(true);
            setCuits([]);
            setOptionCuitVisible(false);
            setCheckEmailVisible(false);
            setRequest({
                ...request,
                dni: null,
                cuit: null,
                birthday: null,
                email: null,
            });
        }
    }, [showDniInput]);

    useEffect(() => {
        // reset EMAIL
        if (showEmailInput) {
            setShowPhoneInput(true);
            setEmail({
                ...setEmail,
                clientEmail: null,
            });
            setCheckPhoneVisible(false);
        }
    }, [showEmailInput]);

    useEffect(() => {
        // reset PHONE
        if (showPhoneInput) {
            setNumber({
                ...setNumber,
                clientNumber: null,
            });
            setCheckPinVisible(false);
        }
    }, [showPhoneInput]);

    useEffect(() => {
        // reset PIN
        if (!checkPinVisible) {
            setrequestButtonVisible(false);
            setTermsAccepted(false);
        }
    }, [checkPinVisible]);

    useEffect(() => {
        // handleChooseDniCuit
        if (cuits?.length > 0) {
            if (cuits.length === 1) {
                setRequest({
                    ...request,
                    cuit: cuits[0].id,
                });
                confirmCuit();
            } else {
                setShowDniInput(true);
                setOptionCuitVisible(true);
            }
        }
    }, [cuits]);

    const styles = {
        container: (isFullWidth) => ({
            marginTop: isFullWidth ? '10px' : '12px',
            minWidth: isFullWidth ? '90%' : '100%',
            marginLeft: isFullWidth ? '10px' : '0px',
            maxWidth: '100%',
            width: isFullWidth ? '90%' : '100%',
        }),
    };

    useEffect(() => {
        if (props.location.search) {
            const searchParams = new URLSearchParams(props.location.search);
            const trackingId = searchParams.get('trackingId');
            const promoter = searchParams.get('promoter');
            const o = searchParams.get('o');

            let filteredMarketingData = {};
            searchParams.forEach((value, key) => {
                if (!key.toLowerCase().startsWith('utm')) {
                    return;
                }
                filteredMarketingData[key] = searchParams.get(key);
            });
            setMarketingData({ _gl: eventTrackingService.getClientId(), ...filteredMarketingData });

            if (o) {
                searchParams.delete('o');
                history.replace({
                    search: searchParams.toString(),
                });
                setEncryptedQueryParams(o);
            }

            if (promoter) {
                const promoterInfo = { name: promoter };
                if (promoter === 'ALPRESTAMO' && trackingId) {
                    promoterInfo.additionalData = {
                        trackingId: trackingId,
                    };
                }
                setPromoterInfo(promoterInfo);
            }
        }
    }, []);

    useEffect(() => {
        async function decryptQueryParams() {
            setLoading(true);
            try {
                let queryParams = (await workflowService.getDecryptedQueryParams(encryptedQueryParams)).data;
                setDni(queryParams.idNumber);
                setOptionCuitVisible(false);
                setCheckPhoneVisible(false);
                setCheckEmailVisible(false);
                setCheckPinVisible(false);
                setrequestButtonVisible(false);
                fillCustomerInputs(queryParams);
                fillPromoterInfo(queryParams);
            } finally {
                setLoading(false);
            }
        }
        if (encryptedQueryParams && encryptedQueryParams.length > 1) {
            decryptQueryParams();
        }
    }, [encryptedQueryParams]);

    const fillCustomerInputs = async (customerData) => {
        setRequest({
            ...request,
            dni: customerData.idNumber,
            birthday: customerData.birthdate,
            email: customerData.email,
            cuit: customerData.taxIdNumber,
        });
        setNumber({
            ...setNumber,
            phone: customerData.cellphone,
            clientNumber: customerData.cellphone,
        });
        setEmail({
            ...setEmail,
            clientEmail: customerData.email,
        });
        eventTrackingService.setDniId(customerData.idNumber);
        setCuits([{ id: customerData.taxIdNumber }]);
        setManualFirstName(customerData.firstName);
        setManualLastName(customerData.lastName);
    };

    const fillPromoterInfo = async (customerData) => {
        if (!promoterInfo.additionalData) {
            promoterInfo.additionalData = {};
        }
        for (const key in customerData) {
            if (customerData.hasOwnProperty(key) && key.startsWith('promoter_data_')) {
                const newKey = key.replace('promoter_data_', '');
                promoterInfo.additionalData[newKey] = customerData[key];
            }
        }
        setPromoterInfo(promoterInfo);
    };

    const getCustomerTaxIds = async (value) => {
        if (value) {
            setLoading(true);
            try {
                const cuits = (await workflowService.getUserInputComboData(null, 'PtllaCUIT', 'stepPreCustomerDataDirect', value)).data;
                setCuits(cuits);
                if (cuits && cuits.length > 1) {
                    setShowDniInput(false);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error.response) {
                    const { errorCode } = error.response.data;
                    if (errorCode === 'ERROR_GETTING_DATA_FROM_DATASOURCE') {
                        setCuits([]);
                        setLoading(false);
                    } else {
                        setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) || 'Error durante la ejecución');
                        setOpen(true);
                    }
                }
            }
        }
    };

    const createLoanRequest = () => {
        const getCurrentTask = async (hashKey) => {
            try {
                const currentTask = (await workflowService.getCurrentTask(hashKey)).data;
                setLoading(false);
                history.push({ pathname: `/${currentTask.taskDefinitionKey}/${hashKey}`, search: history.location.search });
            } catch (error) {
                setLoading(false);
                let errorMessage = 'Algo salió mal. Reintentá en unos segundos.';
                if (error.response) {
                    const { errorCode } = error.response.data;
                    if (errorCode === ERROR.NO_ACTIVE_TASKS) {
                        const loanRequest = (await loanRequestService.getLoanRequest(hashKey)).data;
                        switch (loanRequest.status) {
                            case LOAN_REQUEST_STATUS.REJECTED:
                                eventTrackingService.trackEventWithData(
                                    ANALYTICS_EVENT.NO_OFFER,
                                    {
                                        edad: calculateAge(loanRequest.birthDate),
                                        género: loanRequest.sex,
                                    },
                                    null
                                );
                                history.push({
                                    pathname: `${ROUTE.REJECTED}/${loanRequest.response.rejectedReasonCode}`,
                                    search: history.location.search,
                                });
                                return;
                            case LOAN_REQUEST_STATUS.FINALIZED:
                            case LOAN_REQUEST_STATUS.ACTIVATION_PROCESS:
                                history.push({ pathname: `${ROUTE.SUCCESS}/${hashKey}`, search: history.location.search });
                                return;
                            default:
                                const errorMessageTranslation = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                                if (errorMessageTranslation !== `WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) {
                                    errorMessage = errorMessageTranslation;
                                }
                                break;
                        }
                    }
                }
                setMessage(errorMessage);
                setOpen(true);
            }
        };

        const startDirectLendingWorkflow = async () => {
            try {
                let customerName = request.name || `${manualLastName} ${manualFirstName}`.toUpperCase();
                let result = await workflowService.start(
                    `${request.cuit}`,
                    `${dni}`,
                    customerName,
                    manualFirstName,
                    manualLastName,
                    `${request.sex}`,
                    `${request.birthday}`,
                    `${clientEmail}`,
                    `${clientNumber}`,
                    `${clientPin}`,
                    { _gl: eventTrackingService.getClientId(), ...marketingData },
                    promoterInfo
                );

                let header = result.headers;
                const loanRequest = result.data;
                localStorage.setItem('token', header.authorization);

                eventTrackingService.setTestingGroup(loanRequest.testingGroup);

                if (loanRequest.status !== 'REC') {
                    eventTrackingService.trackEvent('A_B_Testing', null, loanRequest.testingGroup, analyticsStep);
                }

                if (loanRequest.hashKey) {
                    getCurrentTask(loanRequest.hashKey);
                } else {
                    getCurrentTask(result.data.properties.hash);
                }
            } catch (error) {
                setLoading(false);
                if (error.response) {
                    const { cause, errorCode, properties } = error.response.data;
                    switch (errorCode) {
                        case 'ACTIVE_LOAN_REQUEST_SAME_COMMERCE':
                            history.push({ pathname: `/identityValidation/${properties.hash}`, search: history.location.search });
                            break;
                        case 'ACTIVE_LOAN_REQUEST_OTHER_CELLPHONE':
                            setMessage('Ya tenés una solicitud iniciada con otro celular. Por favor ingresá ese mismo número para continuar.');
                            setOpen(true);
                            break;
                        case 'PEN_CREDIT_OTHER_COMMERCE':
                            setMessage(cause || 'Ya tenés una solicitud activa en otro canal');
                            setOpen(true);
                            break;
                        case 'REQUEST_ACTIVE_RECENTLY':
                            setMessage('Ya tenés una solicitud activa. No podés solicitar un nuevo préstamo.');
                            setOpen(true);
                            break;
                        default:
                            setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`));
                            setOpen(true);
                            break;
                    }
                }
            }
        };

        const acceptTerms = async () => {
            try {
                await termService.acceptTerms(request.cuit, termsId);
                startDirectLendingWorkflow();
            } catch (error) {
                setLoading(false);
            }
        };

        setLoading(true);
        acceptTerms();
    };

    let history = useHistory();

    const confirmCuit = () => {
        setOptionCuitVisible(false);
        setCheckPhoneVisible(false);
        setCheckPinVisible(false);
        setCheckEmailVisible(true);
        setrequestButtonVisible(false);
        setShowDniInput(false);
        if (encryptedQueryParams) {
            setCustomerDataComplete(true);
            handleCompleteEmail();
        }
    };

    const handleAcceptTermsChange = (accepted, termsId) => {
        setTermsId(termsId);
        setTermsAccepted(accepted);
    };

    const handleCompleteEmail = () => {
        setCheckPhoneVisible(true);
    };

    const handleCompletePhone = () => {
        setCheckPinVisible(true);
        setrequestButtonVisible(true);
    };

    const checkDni = checkDniVisible ? (
        <CheckDni
            onComplete={getCustomerTaxIds}
            showInput={{ showDniInput, setShowDniInput }}
            optionCuitVisible={{ optionCuitVisible, setOptionCuitVisible }}
        />
    ) : null;

    const optionCuit = optionCuitVisible ? (
        <CheckCuit
            onComplete={confirmCuit}
            manualFirstName={{ manualFirstName, setManualFirstName }}
            manualLastName={{ manualLastName, setManualLastName }}
        />
    ) : null;

    const checkEmail = checkEmailVisible ? <CheckEmail onComplete={handleCompleteEmail} showInput={{ showEmailInput, setShowEmailInput }} /> : null;

    const checkPhone = checkPhoneVisible ? <CheckPhone onComplete={handleCompletePhone} showInput={{ showPhoneInput, setShowPhoneInput }} /> : null;
    const checkPin = checkPinVisible ? <VerifyPin /> : null;

    const handleFormSubmitted = (event) => {
        event.preventDefault();
        if (requestButtonVisible) {
            createLoanRequest();
        }
    };

    const helperInfoPopup = (
        <HelperPopup
            helperText="¿Por qué pedimos tus datos?"
            dialogTitle="¿Por qué pedimos tus datos?"
            dialogAction="Entendido"
            dialogStyle={{ maxWidth: '420px', padding: '0px !important' }}
            dialogTitleClassName="fs-16 fw-700 center mt-1">
            <Typography style={{ margin: '0px 13px', textAlign: 'center', fontSize: '16px', padding: '0px !important' }} gutterBottom>
                Para poder ofrecerte una oferta especial para vos, necesitamos identificar quien sos
            </Typography>
        </HelperPopup>
    );

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    return (
        <Grid container>
            <Loader loading={loading} />
            <Grid container direction="row" justifyContent="flex-start" className="">
                <Grid item xs={12} sm={12} md={12} lg={12} className="">
                    {helperInfoPopup}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="">
                    <form onSubmit={handleFormSubmitted} className="mt-2-5 width-100-per">
                        <Grid item>{checkDni}</Grid>
                        <Grid item>{optionCuit}</Grid>
                        <Grid item>{checkEmail}</Grid>
                        <Grid item>{checkPhone}</Grid>
                        <Grid item>{checkPin}</Grid>
                        {requestButtonVisible && (
                            <Grid item xs={12} sm={12} lg={12} className="fixedBottomOnlyMobile">
                                <Grid container item xs={12} className="fixedTermsMobile">
                                    <AcceptTermsCheckbox onChange={handleAcceptTermsChange} />
                                </Grid>
                                <Grid container xs={10} sm={12}>
                                    <Button
                                        style={styles.container(matches)}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!termsAccepted}>
                                        COMENZAR
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>
                <Hidden xsDown>
                    <Grid item sm={1}></Grid>
                </Hidden>
            </Grid>
            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />{' '}
        </Grid>
    );
}
