import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    button: {
        color: '#fff',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '20px',
        width: '411px',
        marginTop: '40px',
        marginBottom: '40px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '40px',
        paddingRight: '40px',
        textTransform: 'none',
    },
    fixed: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
}));

function MainButton({ fixedBottomMobile, ...props }) {
    const classes = useStyles();
    const fixedBottomMobileClass = fixedBottomMobile ? classes.fixed : '';

    return (
        <Button {...props} variant="contained" color="primary" className={`${classes.button} ${fixedBottomMobileClass}`}>
            {props.children}
        </Button>
    );
}

export default MainButton;
