import { Box, Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import UserInputs from '../components/userInputs/UserInputs';
import eventTrackingService from '../services/eventtracking.service';
import loanRequestService from '../services/loanRequest.service';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';

export const SelectOfficeForAccountOpening = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [loanRequest, setLoanRequest] = useState(null);
    const [validForm, setValidForm] = useState(false);
    const [userInputs, setUserInputs] = useState({});
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/prestamos-personales/seleccion-sucursal');
    }, []);

    useEffect(() => {
        async function getLoanRequest() {
            try {
                setLoanRequest((await loanRequestService.getLoanRequest(hashKey)).data);
            } catch (error) {
                console.error(error.response);
            }
        }

        getLoanRequest();
    }, [hashKey]);

    useEffect(() => {
        setValidForm(true);
    }, [userInputs]);

    const handleNextPressed = async (event) => {
        event.preventDefault();
        try {
            eventTrackingService.trackEventWithData('prestamos_seleccion_sucursal', { sucursal: userInputs['PtllaSucursalApertura'] }, null);
            await loanRequestService.setOfficeDataForAccountOpening(hashKey, userInputs);
            task.completeTask();
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                const { fieldsToCompleted } = properties;
                if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED') {
                    var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                    fieldsToCompleted.map((field) => (msg += ' ' + t(`FIELDS.${field}`)));
                    setMessage(msg);
                    setOpen(true);
                }
            } else {
                console.error(error);
            }
        }
    };

    const handleComplete = (userInputFilled) => {
        setUserInputs(userInputFilled);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    return (
        <MacroStepPage
            headerLead="Ya completaste tus datos personales"
            headerTitle="Completá los datos para la apertura de Cuenta"
            hashKey={hashKey}
            task={task}
            taskDefinitionKey={taskDefinitionKey}
            backToText="Completar tus datos personales"
            hideStepper={true}
            stepNumber={3}
            totalSteps={4}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <>
                    <form onSubmit={handleNextPressed}>
                        <Grid container>
                            {loanRequest && <UserInputs stepName={taskDefinitionKey} hashKey={loanRequest.hashKey} onComplete={handleComplete} />}
                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="center" xs={12}>
                            <Grid item xs={12}>
                                <Box className="mt-5"></Box>
                            </Grid>

                            <Grid item xs={12} sm={5} md={6} lg={4}>
                                <div className="startButton center mb-20-web mt-20-web">
                                    <Button className="width-90-per" type="submit" variant="contained" color="primary" disabled={!validForm}>
                                        Siguiente
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                    </form>
                </>
            )}
        </MacroStepPage>
    );
};
