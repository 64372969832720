import { Box, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';
import eventTrackingService from '../../services/eventtracking.service';
import '../../styles/components/financialOptionsSummary.scss';
import { numberToCurrencyText, numberToPercentageText } from './../utils/numberUtils';

const FinancialOptionsSummary = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSmSize = useMediaQuery(theme.breakpoints.down('sm'));
    const { loanRequest } = props;

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/prestamos-personales/validacion-mail');
    }, []);

    return (
        <Grid container direction="row">
            <Grid item xs={12}>
                <h5 className={matchesSmSize ? classes.titleTextMobile : classes.titleText}>
                    Solicitaste un Préstamo por el monto de{' '}
                    <span className="titleHightlightText">{numberToCurrencyText(loanRequest.requestedAmount)}</span> en{' '}
                    {loanRequest.installmentsNumberSelected} cuotas promedio de {numberToCurrencyText(loanRequest.installmentAmountSelected)}*
                </h5>
                <Box m={1} />
                {/* <a className="link">Modificar monto o cuotas</a> */}
            </Grid>

            <Grid item container xs={12} sm={6} style={{ marginTop: '20px' }} className={['mt-2', matchesSmSize ? 'tableTextMobile' : 'tableText']}>
                <Grid container>
                    <Grid item xs={9}>
                        <p>Sistema de amortización</p>
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        <p>Francés</p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9}>
                        <p>Tipo de tasa</p>
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        <p>Fija</p>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={9}>
                        <p>Monto Total a Pagar</p>
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        <p>{numberToCurrencyText(loanRequest.totalRepaymentAmount)}</p>
                    </Grid>
                </Grid>

                <Grid container className="mt-2">
                    <Grid item xs={4}>
                        <p className="fs-14 fw-500 break-word">TNA: {numberToPercentageText(loanRequest.tna)}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <p className="fs-14 fw-500 break-word">TEA: {numberToPercentageText(loanRequest.tea)}</p>
                    </Grid>

                    <Grid item xs={4}>
                        <p className="fs-14 fw-500 break-word">CFTEA: {numberToPercentageText(loanRequest.cft)}</p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    titleText: {
        fontFamily: 'Roboto',
        fontSize: '28px',
        fontWeight: '500',
        lineHeight: '40px',
        color: '#082f57',
    },
    titleTextMobile: {
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '26px',
        color: '#082f57',
    },
    ratesText: {
        fontSize: '14px',
        lineHeight: '24px',
        color: '#515151',
    },
    cftText: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#515151',
        fontWeight: 'bold',
    },
}));

export default FinancialOptionsSummary;
